@import "varibles";

@font-face {
  font-family: "ProximaNova";
  src: url('/fonts/ProximaNova-RegularIt.ttf');
  src: url('/fonts/ProximaNova-Regular.woff') format('woff'),
  url('/fonts/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNovaBlack";
  src: url('/fonts/ProximaNova-Black.ttf');
  src: url('/fonts/ProximaNova-Black.woff') format('woff'),
  url('/fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
}

body {
  font-size: 14px;
  font-family: $pn;
  color: $grey;
  line-height: 1.4;
  position: initial;
  min-height: 100%;
  background-color: #fff;
}

p {
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.6;
}

a {
  color: $grey;
  transition: all 0.3s;
  text-decoration: none;

  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.site-width {
  max-width: 1190px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

/******* HEADER *******/
.header {
  border-bottom: 1px solid #D2D2D2;
  height: 85px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  @include respond-to(small) {
    height: 68px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__nav {
    display: flex;
    justify-content: space-between;

    a {
      color: $grey;
      font-family: $pn;
      display: inline-block;
      font-size: 16px;
      margin-right: 46px;
      position: relative;
      transition: all 0.3s;

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: transparent;
        transition: all 0.4s;
      }

      &:after {
        left: auto;
        right: 0;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $blue;
        transition: all 0.3s;

        &:after,
        &:before {
          width: 50%;
          transition: all 0.5s;
          background: $blue;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include respond-to(small) {
      display: none;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      margin-left: 24px;
      height: 28px;
      width: 28px;
      background-image: url("/images/icon.png");

      &:first-child {
        background-position: top -2px left -2px;
      }

      &:nth-child(2) {
        background-position: top -2px left 61px;
      }

      &:last-child {
        background-position: top -2px right -2px;
      }

      &:hover,
      &:focus,
      &:active {
        &:first-child {
          background-position: bottom -2px left -2px;
        }

        &:nth-child(2) {
          background-position: bottom -2px left 61px;
        }

        &:last-child {
          background-position: bottom -2px right -2px;
        }
      }
    }
  }

  &__mob {
    display: none;
    @include respond-to(small) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #fff;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: all .7s;
      justify-content: space-between;
      padding: 70px 20px;
      z-index: 100;
    }

    &.open {
      transition: all .7s;
      transform: translateX(0);
    }

    .header__nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-right: 0;
        margin-bottom: 36px;
      }
    }

    .header__icon {
      position: absolute;
      top: 20px;
      left: 20px;

      img {
        width: auto;
        height: auto;
      }
    }
  }

  &__icon {
    display: none;
    @include respond-to(small) {
      display: flex;
      align-items: center;
      width: 30px;
      height: 25px;
      cursor: pointer;
    }

    img {
      width: 20px;
      height: 12px;
      max-width: 100%;
    }

  }

}

/******* FOOTER *******/

.footer {
  background: #F6F6F6;
  padding-top: 40px;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    @include respond-to(500){
      flex-direction: column;
      align-items: center;
    }

    a {
      color: $grey;
      font-family: $pn;
      display: inline-block;
      font-size: 16px;
      margin-right: 46px;
      position: relative;
      transition: all 0.3s;
      @include respond-to(500){
        margin-bottom: 20px;
        margin-right: 0;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: transparent;
        transition: all 0.4s;
      }

      &:after {
        left: auto;
        right: 0;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $blue;
        transition: all 0.3s;

        &:after,
        &:before {
          width: 50%;
          transition: all 0.5s;
          background: $blue;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: 0;
    @include respond-to(800) {
      position: relative;
      top: 0;
      width: 100%;
      margin-top: 20px;
    }
    @include respond-to(500){
      margin-top: 0;
    }

    a {
      display: flex;
      margin-left: 24px;
      height: 28px;
      width: 28px;
      &:first-child{
        margin-left: 0;
      }
      background-image: url("/images/icon.png");

      &:first-child {
        background-position: top -2px left -2px;
      }

      &:nth-child(2) {
        background-position: top -2px left 61px;
      }

      &:last-child {
        background-position: top -2px right -2px;
      }

      &:hover,
      &:focus,
      &:active {
        &:first-child {
          background-position: bottom -2px left -2px;
        }

        &:nth-child(2) {
          background-position: bottom -2px left 61px;
        }

        &:last-child {
          background-position: bottom -2px right -2px;
        }
      }
    }
  }

  &__copyright {
    text-align: center;
    width: 100%;
    color: $grey;
    font-size: 14px;
    margin-top: 20px;
    padding: 0 0 10px;
    @include respond-to(480) {
      padding: 0 0 15px;
    }
  }
}
