/*
  Top Section
 */
.top {
  height: 100vh;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  padding-top: 11vh;
  @include respond-to(small){
    height: auto;
    padding-top: 104px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    width: 50%;
    padding-right: 40px;
    @include respond-to(small){
      width: 100%;
      text-align: center;
      padding-right: 0;
    }

    h2 {
      font-size: 64px;
      color: $blue;
      font-weight: bold;
      font-family: $pnBlack;
      margin-bottom: 5vh;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      @include respond-to(small){
        text-align: left;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("/images/points.png");
        width: 76px;
        height: 58px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
      }

      &:after {
        top: auto;
        left: auto;
        bottom: 21px;
        right: 21px;
      }
      @include respond-to(1080){
        font-size: 40px;
        &:after,
        &:before{
          width: 40px;
          height: 39px;
          top: -3px;
        }
        &:after{
          bottom: 13px;
          right: 11px;
          top: auto;
        }
      }
    }
    img{
      display: none;
      @include respond-to(small){
        display: block;
        max-width: 320px;
        width: 100%;
        margin: 0 auto;
      }
    }
    p {
      margin-bottom: 3vh;
    }

    a {
      padding: 14px 18px;
      color: #fff;
      background: $blue;
      display: inline-block;
      line-height: 1;
      text-decoration: none;
      transition: background-color .3s;
      border-radius: 4px;
      font-weight: bold;

      &:hover,
      &:active,
      &:focus {
        background: #89CDE6;
        transition: background-color .3s;
      }
    }
  }

  &__car {
    width: 50%;
    padding: 10px;

    img {
      max-height: 70vh;
      width: auto;
      @include respond-to(1150){
        max-height: 62vh;
      }
      @include respond-to(960){
        max-height: 50vh;
      }
      @include respond-to(840){
        max-height: 45vh;
      }
    }
    @include respond-to(small){
      display: none;
    }
  }

  &__logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  @include respond-to(1000){
    margin-top: 16vh;
  }
    img {
      &:nth-child(2) {
        margin: 0 72px;
      }
    }
  }
}

/*
  ================
 */

/**
  Section What We Do
 */
.we-do {
  margin-bottom: 80px;

  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    max-width: 760px;
    text-align: center;
  }
}

/*
  ================
 */

/**
  Case Study
 */

.case-study {
  margin-bottom: 50px;

  &__item {
    text-align: center;
    transition: all .3s;
    position: relative;

    &:before {
      content: '';
      height: 5px;
      width: 100%;
      background: transparent;
      position: absolute;
      top: 0;
      transition: all .3s;
      left: 0;
    }

    &:hover {
      transition: all .3s;
      box-shadow: 0 4px 9px rgba(0, 0, 0, .16);
      z-index: 100;
      color: $blue;
      cursor: pointer;

      &:before {
        background: $blue;
        transition: all .3s;
      }
    }
    img{
      @include respond-to(540){
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  &__description {
    font-size: 20px;
    margin: 20px 0 12px;
    padding: 0 10px;

  }

  &__nav {
    display: flex;
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    @include respond-to(540){
      justify-content: space-between;
      padding: 0 40px;
    }

    &_modal {
      bottom: auto;
      top: 120px;
      justify-content: space-between;
      @include respond-to(540){
        padding: 0;
      }
    }

    &__item {
      margin: 0 100px;
      cursor: pointer;
      @include respond-to(small){
        margin: 0 10px;
      }
    }

    &__btn {
      position: relative;
      z-index: 3;
      width: 30px;
      height: 30px;
      margin: 0 100px;
      border-radius: 100%;
      color: $blue;
      font-size: 14px;
      border: 1px solid $blue;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      @include respond-to(540){
        margin: 0;
      }

      &:hover,
      &:focus,
      &:active {
        transition: all .3s;
        background: $blue;
        color: #fff;
        text-decoration: none;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .26);
      }

      &_modal {
        margin: 0 0 5px;
      }
    }
  }

  &__slider {
    position: relative;
  }

  .slick-list {
    padding: 0 5px 5px;
  }

  .slick-dots {
    margin-top: 50px;
    position: relative;
    bottom: 0;
    z-index: 2;

    li {
      width: 6px;
      height: 6px;
      margin: 0 8px;

      &.slick-active {
        button {
          background: $blue;
        }
      }

      button {
        width: 6px;
        height: 6px;
        background: $grey;

        &:before {
          display: none;
        }
      }
    }
  }
}

/**
  Modal Slider
 */
.modal-slider {
  &.modal-dialog {
    max-width: 768px;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    border-bottom: none;
    border-radius: 0;
    border-top: 5px solid $blue;
  }

  .modal-body {
    padding: 10px 20px 30px;
  }

  &__item {
    text-align: justify;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex !important;

    img {
      max-width: 270px;
      width: 100%;
    }

    h4 {
      font-size: 20px;
      color: $blue;
      margin: 30px 0 20px;
      font-weight: bold;
      text-align: center;

      &:before {
        content: '';
        background-image: url(/images/line.png);
        display: inline-block;
        width: 28px;
        height: 18px;
        background-size: cover;
        margin-right: 10px;
        position: relative;
        top: 3px;
      }
    }
  }
}

/*
  ================
 */

/*
  Slils
 */
.skills {
  text-align: center;
  margin-bottom: 80px;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

  }

  &__list {
    width: 50%;
    border-left: 4px solid $blue;
    padding: 0 10px;
    margin-bottom: 40px;
    text-align: left;
    @include respond-to(485){
      width: 100%;
    }
  }
}

/*
  ================
 */

/**
  Our Team
 */
.our-team {
  margin-bottom: 150px;
  position: relative;
  @include respond-to(small){
    margin-bottom: 80px;
  }
  &:before{
    content: '';
    background-image: url(/images/grey-line.png);
    background-size: cover;
    background-position: center;
    display: block;
    position: absolute;
    top: -116px;
    right: 0;
    width: 100%;
    height: 800px;
    z-index: -1;
    @include respond-to(800){
      display: none;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: -50px;
    margin-left: -50px;
    @include respond-to(1280){
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__item {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 50px;
    @include respond-to(800){
      width: 50%;
      padding: 0 20px;
    }
    @include respond-to(460){
      width: 100%;
    }
    h5{
      font-size: 18px;
      margin:15px 0 20px;
      color: #242424;
    }
  }
}

/*
  ================
 */

/**
  Logo
 */
.logo{
  margin-bottom: 70px;
  &__wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
      width: 100%;
      max-width: 380px;
    }
  }
}
/*
  ================
 */