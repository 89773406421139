/**
==============================
Color
==============================
 */
$grey: #9B9B9B;
$blue: #2AC5FF;
/*
==============================
Fonts
==============================
*/
$pn: "ProximaNova", sans-serif;
$pnBlack: "ProximaNovaBlack", sans-serif;
