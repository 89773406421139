
// ex .element{ @include respond-to(small) { ... } }
@mixin respond-to($breakpoint) {
	@if $breakpoint == "small" {
		@media (max-width: 768px) {
			@content;
		}
	}
	@elseif $breakpoint == "xs" {
		@media (max-width: 550px) {
			@content;
		}
	}

	@else {
		@media (max-width: #{$breakpoint}px) {
			@content;
		}
	}
}
@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}  
}
@mixin flex(){
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin transition(){
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}

@mixin l-gradient($direction, $color-start, $color-stop) {
background: $color-start;
    background: -moz-linear-gradient($direction, $color-start 0%, $color-stop );
    background: -webkit-linear-gradient($direction, $color-start 0%, $color-stop );
    background: -o-linear-gradient($direction, $color-start 0%, $color-stop );
    background: -ms-linear-gradient($direction, $color-start 0%, $color-stop );
    background: linear-gradient($direction, $color-start 0%, $color-stop );
}