/****  preloader   ******/
@-webkit-keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.site-preload {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;

  &__load {
    background-color: transparent;
    border-top: 5px solid rgba(0, 0, 0, .2);
    border-right: 5px solid rgba(0, 0, 0, .2);
    border-bottom: 5px solid rgba(0, 0, 0, .2);
    border-left: 5px solid #eee;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
    -webkit-animation-name: animsition-loading;
    animation-name: animsition-loading;
    width: 32px;
    height: 32px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    z-index: 2;

    &:after {
      width: 32px;
      height: 32px;
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -16px;
      margin-left: -16px;
      border-radius: 50%;
      z-index: 2;
    }
  }
}

//mobile menu
@keyframes click-expand {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

//////////////////////////////////////

/**
  Modal Form
 */
.modal-form {
  .modal-dialog {
    max-width: 465px;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    border-bottom: none;
    border-radius: 0;
  }

  .modal-body {
    padding: 0 80px 50px;
    @include respond-to(small){
      padding: 0 40px 50px;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;
  }

  label {
    color: #AFAFAF;
    position: absolute;
    left: 20px;
    top: 14px;
    transition: all .3s;

    &.js-active-input {
      transition: all .3s;
      font-size: 12px;
      background: #fff;
      top: -9px;
      color: #242424;
      padding: 1px 5px;
    }
  }

  .form-control {
    resize: none;
    border-radius: 4px;
    border-color: #AFAFAF;
    box-shadow: none;
    outline: none;
    padding: 10px;
    height: auto;
    color: #242424;

    &:focus {
      border-color: $blue;
    }
  }

  .btn {
    background: $blue;
    border-radius: 2px;
    width: 100%;
    border: 2px solid $blue;
    color: #fff;
    text-transform: uppercase;
    outline: none;
    box-shadow: none;
    transition: all .3s;

    &:hover {
      background: #fff;
      color: $blue;
      transition: all .3s;
    }
  }
}

/**
====================
 */

/**
  Section Title
 */

.section-title {
  font-size: 30px;
  text-transform: uppercase;
  color: #242424;
  margin-bottom: 40px;
  font-weight: bold;
  text-align: center;
  @include respond-to(small){
    font-size: 24px;
  }

  &:before {
    content: '';
    background-image: url(/images/line.png);
    display: inline-block;
    width: 35px;
    height: 24px;
    background-size: cover;
    margin-right: 10px;
    position: relative;
    top: 3px;
    @include respond-to(small){
      top: 5px;
    }
  }

  &_logo{
    font-size: 45px;
    color: $blue;
    font-family: $pnBlack;
    @include respond-to(small){
      font-size: 34px;
    }
    &:before{
      width: 54px;
      height: 37px;
      top: 5px;
      @include respond-to(small){
        width: 42px;
        height: 28px;
        top: 2px;
      }
    }
  }
}

/**
 =================
 */

/**
  Scroll Link
 */
.scroll-link {
  font-size: 16px;
  color: #242424;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s;

  &.scroll-link_active {
    &:after {
      transform: rotate(90deg);
      transition: all 0.3s;
    }
  }

  &:after {
    content: '\2039';
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    font-size: 30px;
    width: 20px;
    height: 20px;
    color: #242424;
    transition: all 0.3s;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $blue;
    transition: all 0.3s;

    &:after {
      color: $blue;
      transition: all 0.3s;
    }

    &:before {
      content: '';
      position: absolute;
      top: 19px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $blue;
      transition: all 0.3s;
    }
  }
}

.js-we-do-container-text {
  display: none;
}

/**
 =================
 */
